import React from 'react';

import styled from 'styled-components';

import Button from 'lib/Button';

import FieldHeader from './FieldHeader';

const FieldRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 24px;
`;

const FieldRowActions = styled.div`
	display: flex;
`;

const FieldRowAction = styled.div`
	margin-left: 16px;
	min-width: 88px;
`;

interface FieldActionRowProps {
	label: string;
	yesActionEnabled: boolean;
	noActionEnabled: boolean;
	onYesClick(): void;
	onNoClick(): void;
}

const FieldActionRow = ({
	label,
	yesActionEnabled,
	noActionEnabled,
	onYesClick,
	onNoClick,
}: FieldActionRowProps) => (
	<FieldRow>
		<FieldHeader>{label}</FieldHeader>
		<FieldRowActions>
			<FieldRowAction>
				<Button height={48} isOutline={!yesActionEnabled} onClick={onYesClick}>
					Yes
				</Button>
			</FieldRowAction>
			<FieldRowAction>
				<Button height={48} isOutline={!noActionEnabled} onClick={onNoClick}>
					No
				</Button>
			</FieldRowAction>
		</FieldRowActions>
	</FieldRow>
);

export default FieldActionRow;
