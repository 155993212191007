import React from 'react';

import styled from 'styled-components';

import Title from 'lib/Title';
import theme from 'config/theme';
import { Feature, FeatureCategory } from 'models/property';
import SubTitle from 'lib/SubTitle';
import CheckboxRow from 'lib/CheckboxRow';

import FieldHeader from './FieldHeader';

const { colors } = theme;

interface RulesProps {
	features: Feature[];
	onFeaturesChange(value: Feature[]): void;
}

const Rules = ({ features, onFeaturesChange }: RulesProps) => {
	const handleFeatureToggle = (featureId: number) => {
		onFeaturesChange(
			features.map(f => ({ ...f, active: f.id === featureId ? !f.active : f.active })),
		);
	};
	return (
		<>
			<Title standardMargin>What about the property rules?</Title>
			<SubHeader>
				What rules will tenants have to follow in order to stay at this property
			</SubHeader>
			<Container>
				<FieldHeader>Select the rules below that apply</FieldHeader>
				{features
					.filter(({ category }) => category === FeatureCategory.Rules)
					.sort((a, b) => {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}
						return 0;
					})
					.map(item => (
						<div key={item.id}>
							<CheckboxRow
								text={item.name}
								checked={item.active}
								onClick={() => handleFeatureToggle(item.id)}
							/>
						</div>
					))}
			</Container>
		</>
	);
};

export default Rules;

const Container = styled.div`
	padding-bottom: 32px;
`;

const SubHeader = styled(SubTitle)`
	color: ${colors.grey60};
	margin-bottom: 24px;
`;
