import React, { createRef, useState, useEffect } from 'react';

import styled from 'styled-components';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';

import theme from 'config/theme';
import Title from 'lib/Title';
import SubTitle from 'lib/SubTitle';
import Input from 'lib/Input';
import BoldUrl from '../../assets/images/add-a-digs-v2/bold.png';
import ItalicUrl from '../../assets/images/add-a-digs-v2/italic.png';
import UnorderedListUrl from '../../assets/images/icons/bullet-list.svg';
import OrderedList from '../../assets/images/icons/number-list.svg';

const { colors, inputs, fontWeights, fontSizes } = theme;

interface NameListingProps {
	pageTitle?: string;
	title: string;
	description: string;
	onTitleChange(value: string): void;
	onDescriptionChange(value: string): void;
}

interface EditButtonProps {
	url: string;
	cmd: string;
	active: boolean;
	onActivate(value: boolean): void;
}

const sanitizeConf = {
	allowedTags: ['b', 'i', 'ol', 'ul', 'li', 'br'],
	allowedAttributes: {},
};

const sanitize = (description: any) => {
	return sanitizeHtml(description, sanitizeConf);
};

const EditButton = ({ active, url, cmd, onActivate }: EditButtonProps) => (
	<TagButton
		active={active}
		key={cmd}
		onClick={() => {
			onActivate(!active);
			document.getElementById('cdedit')?.focus();
			document.execCommand(cmd, false, '');
		}}
	>
		<TagImage src={url} />
	</TagButton>
);

const selectionEnabled = (
	command: 'bold' | 'italic' | 'insertOrderedList' | 'insertUnorderedList',
) => {
	if (document.queryCommandState) {
		return document.queryCommandState(command);
	}
	return false;
};

const NameListing = ({
	pageTitle,
	title,
	description,
	onTitleChange,
	onDescriptionChange,
}: NameListingProps) => {
	const ref = createRef<HTMLDivElement>();
	const [bold, setBold] = useState(false);
	const [italics, setItalics] = useState(false);
	const [orderedList, setOrderedList] = useState(false);
	const [unorderedList, setUnorderedList] = useState(false);

	useEffect(() => {
		if (!ref || !ref.current) {
			return;
		}

		const handler = (e: any) => {
			setBold(selectionEnabled('bold'));
			setItalics(selectionEnabled('italic'));
			setUnorderedList(selectionEnabled('insertUnorderedList'));
			setOrderedList(selectionEnabled('insertOrderedList'));
		};

		document.addEventListener('selectionchange', handler);

		return () => {
			document.removeEventListener('selectionchange', handler);
		};
	}, [ref]);

	return (
		<>
			<Title standardMargin>{pageTitle || 'Create your title'}</Title>
			<SubHeader>
				Tell your potential tenants about how incredible your place is. Give your listing a
				title, and a description.
			</SubHeader>
			<Container ref={ref}>
				<Input placeholder="Listing title" value={title} onChange={onTitleChange} />
				<ContentEditableTextArea
					id="cdedit"
					tagName="pre"
					html={description}
					onChange={event => onDescriptionChange(event.target.value)}
				/>
				<EditButton active={bold} url={BoldUrl} cmd="bold" onActivate={setBold} />
				<EditButton active={italics} url={ItalicUrl} cmd="italic" onActivate={setItalics} />
				<EditButton
					active={orderedList}
					url={OrderedList}
					cmd="insertOrderedList"
					onActivate={setOrderedList}
				/>
				<EditButton
					active={unorderedList}
					url={UnorderedListUrl}
					cmd="insertUnorderedList"
					onActivate={setUnorderedList}
				/>
			</Container>
		</>
	);
};

export default NameListing;

const Container = styled.div`
	padding-bottom: 32px;
`;

const SubHeader = styled(SubTitle)`
	color: ${colors.grey60};
	margin-bottom: 24px;
`;

const ContentEditableTextArea = styled(ContentEditable)`
	display: list-item;
	width: 100%;
	min-width: 100%;
	height: 100px;
	outline: transparent;
	border-radius: ${inputs.borderRadius};
	font-family: inherit;
	padding: 16px;
	margin-bottom: 16px;
	color: ${colors.darkBlue};
	resize: none;
	background: ${colors.grey05};
	white-space: pre-line;
	overflow: auto;

	:empty:before {
		content: 'Share a little bit about your property.';
		color: ${colors.grey60};
		font-weight: ${fontWeights.normal};
		font-size: ${fontSizes.small};
	}

	ul {
		margin-top: 10px;
		margin-left: 10px;
		margin-bottom: 10px;

		li {
			margin-left: 10px;
			list-style-type: disc;
		}
	}

	ol {
		margin-top: 10px;
		margin-left: 10px;
		margin-bottom: 10px;

		li {
			margin-left: 10px;
			list-style-type: decimal;
		}
	}
`;

const TagButton = styled.button<{ active?: boolean }>`
	margin: 1px;
	min-width: 34px;
	border-radius: 4px;
	min-height: 34px;
	background-color: ${({ active }) => (active ? colors.grey10 : colors.white)};
`;

const TagImage = styled.img`
	margin-top: 4px;
	width: 24px;
	height: 24px;
`;
