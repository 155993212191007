import React from 'react';

import styled from 'styled-components';

import { ReactComponent as BinIcon } from 'assets/images/add-digs/bin.svg';

interface DeleteImageButtonProps {
	onClick(): void;
}

const DeleteImageButton = ({ onClick }: DeleteImageButtonProps) => (
	<DeleteImageButtonContainer onClick={onClick}>
		<BinIcon />
	</DeleteImageButtonContainer>
);

export default DeleteImageButton;

const DeleteImageButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 8px;
	right: 8px;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.5);
	cursor: pointer;
	padding: 4px;
`;
