import React from 'react';

import styled from 'styled-components';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';

import Title from 'lib/Title';
import theme from 'config/theme';
import SubTitle from 'lib/SubTitle';
import { AutoComplete } from 'lib/AutoComplete';
import { MAPBOX_ACCESS_TOKEN } from 'config/keys';
import { ReactComponent as MarkerIcon } from 'assets/images/icons/location-pin.svg';
import { Address as AddressData } from 'models/property';

import InfoBox from './InfoBox';

const Map = ReactMapboxGl({
	accessToken: MAPBOX_ACCESS_TOKEN,
});

const { colors, mapBoxTheme } = theme;

const containerStyle = {
	backgroundColor: colors.lightGray,
	height: '100%',
	minHeight: '250px',
	width: '100%',
	position: 'relative',
	top: '0',
	left: '0',
};

const defaultCoords = { lat: -30.5595, lng: 22.9375 };

interface AddressProps {
	address?: AddressData;
	onAddressChange(address: any): any;
}

const Address = ({ address, onAddressChange }: AddressProps) => {
	const coordinates =
		address && address.coords
			? { lat: address.coords.latitude, lng: address.coords.longitude }
			: defaultCoords;

	return (
		<PageContainer>
			<Title standardMargin>Where is the property located?</Title>
			<SubHeader>Search for your property's address below.</SubHeader>
			<InfoBox
				text="DigsConnect will not display your property's exact address. We will only display the
						suburb of the address you provided."
			/>
			<MapContainer>
				<Map
					style={mapBoxTheme}
					zoom={[5]}
					center={[coordinates.lng, coordinates.lat]}
					containerStyle={containerStyle as any}
				>
					<Marker coordinates={[coordinates.lng, coordinates.lat]}>
						<MarkerIcon />
					</Marker>
				</Map>
				<AutoCompleteContainer>
					<AutoComplete
						showAllCountries={true}
						placeholder={
							(address && address.formatted_address) || "Type in your property's address"
						}
						isPlaceholderValue={!!address && !!address.formatted_address}
						onChange={value => {
							onAddressChange({
								...value,
								formatted_address: value.description,
							});
						}}
					/>
				</AutoCompleteContainer>
			</MapContainer>
		</PageContainer>
	);
};

export default Address;

const PageContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	height: 100%;
`;

const SubHeader = styled(SubTitle)`
	color: ${colors.grey60};
	margin-bottom: 24px;
`;

const MapContainer = styled.div`
	flex: 1;
	position: relative;
	width: 100%;
	min-width: 200px;
	overflow: hidden;
	height: 220px;
	margin-top: 24px;
	margin-bottom: 24px;
	border-radius: 16px;
`;

const AutoCompleteContainer = styled.div`
	position: absolute;
	top: 24px;
	left: 24px;
	right: 24px;
	max-width: 600px;
	margin: auto;
	z-index: 4;
`;
