import React from 'react';

import styled from 'styled-components';

import Title from 'lib/Title';
import SubTitle from 'lib/SubTitle';
import theme from 'config/theme';
import { Room } from 'models/property';
import { ReactComponent as RightArrow } from 'assets/images/icons/arrow-right-blue.svg';
import editDigsService from './editDigsService';
import { LeaseType, PropertyType } from 'models/digs';

const { colors } = theme;

const Container = styled.div`
	margin-top: 40px;
	color: ${colors.darkBlue};
`;

const SubHeader = styled(SubTitle)`
	color: ${colors.grey60};
`;

const RoomTitle = styled(SubTitle)`
	margin: 0;
	font-weight: 600;
`;

const RoomCard = styled.div`
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	padding: 32px 24px;
	margin-bottom: 24px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ErrorText = styled.div`
	color: ${colors.red};
	font-weight: 600;
	font-size: 14px;
	margin-top: 8px;
`;

interface RoomsProps {
	rooms: Room[];
	leaseType: LeaseType;
	propertyType: PropertyType;
	onRoomSelect(room: Room): void;
}

const Rooms = ({ rooms, leaseType, propertyType, onRoomSelect }: RoomsProps) => {
	return (
		<>
			<Title standardMargin>Rooms</Title>
			<SubHeader>What do the bedrooms include?</SubHeader>
			<Container>
				{rooms.map(room => (
					<RoomCard key={room.uuid} onClick={() => onRoomSelect(room)}>
						<div>
							<RoomTitle>{room.title}</RoomTitle>
							{!editDigsService.validateRoom(room, leaseType, propertyType).valid && (
								<ErrorText>Required fields</ErrorText>
							)}
						</div>
						<RightArrow />
					</RoomCard>
				))}
			</Container>
		</>
	);
};

export default Rooms;
