import React from 'react';

import styled from 'styled-components';

import Title from 'lib/Title';
import theme from 'config/theme';
import SubTitle from 'lib/SubTitle';
import Input from 'lib/Input';
import { Video as VideoPlayer } from 'lib/Video';

import FieldHeader from './FieldHeader';

const { colors } = theme;

interface VideoProps {
	link?: string;
	onLinkChange(value: string): void;
}

const Video = ({ link, onLinkChange }: VideoProps) => {
	return (
		<>
			<Title standardMargin>What about a video walk-through?</Title>
			<SubHeader>
				Add a video link to show off your place to potential tenants. This can have a great impact
				on your listing score!
			</SubHeader>
			<WarningText>This is not required, or you can add it at a later stage.</WarningText>
			<Container>
				<FieldHeader>Paste a video walk-through link here</FieldHeader>
				<Input
					placeholder="Insert link here"
					value={link}
					onChange={val => {
						try {
							const videoId = new URLSearchParams(new URL(val.toString()).search).get('v');
							const embedLink = `https://www.youtube.com/embed/${videoId}`;
							onLinkChange(embedLink);
						} catch (e) {
							onLinkChange('');
						}
					}}
				/>
				{link && <VideoPlayer videoUrl={link} />}
			</Container>
		</>
	);
};

export default Video;

const Container = styled.div`
	padding-bottom: 32px;
`;

const SubHeader = styled(SubTitle)`
	color: ${colors.grey60};
	margin-bottom: 16px;
`;

const WarningText = styled.div`
	color: ${colors.pink};
	font-weight: 600;
	margin-bottom: 24px;
`;
