import React, { useRef, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as PlaceHolderImage } from 'assets/images/add-digs/upload-placeholder.svg';
import Title from 'lib/Title';
import theme from 'config/theme';
import SubTitle from 'lib/SubTitle';
import Button from 'lib/Button';
import { Photo } from 'models/property';

import DeleteImageButton from './DeleteImageButton';
import { Select } from 'lib/Select';

const { colors } = theme;

interface PictureUploadProps {
	pictures: Photo[];
	tags: string[];
	onUpload(data: FormData): void;
	onUploadMultiple(photosData: FormData[]): void;
	onDelete(id: string): void;
	onReorder(photos: Photo[]): void;
	onPhotoUpdate(photo: Photo): void;
}

interface PhotoDisplay extends Photo {
	isPlaceholder?: boolean;
}

const padAndOrderPhotos = (photos: Photo[]): PhotoDisplay[] => {
	const sortedPhotos = [...photos].sort(
		(a, b) => (a.ordinal_position || 0) - (b.ordinal_position || 0),
	);
	if (sortedPhotos.length >= 3) {
		return sortedPhotos;
	}
	const paddedPhotos = [1, 2, 3].map(
		(_, i) =>
			({
				uuid: `placeholder_${i}`,
				isPlaceholder: true,
			} as PhotoDisplay),
	);
	return [
		...sortedPhotos.sort((a, b) => (a.ordinal_position || 0) - (b.ordinal_position || 0)),
		...paddedPhotos,
	].slice(0, 3);
};

const PictureUpload = ({
	pictures,
	tags,
	onUpload,
	onUploadMultiple,
	onDelete,
	onReorder,
	onPhotoUpdate,
}: PictureUploadProps) => {
	const ref = useRef<HTMLInputElement>(null);
	const [dragPhoto, setDragPhoto] = useState<Photo>();
	const [dropZonePhotoId, setDropZonePhotoId] = useState<string>();
	const displayPhotos = padAndOrderPhotos(pictures);

	const tagOptions = tags.map(tag => ({ value: tag, label: tag }));

	const handleUploadClick = async () => {
		if (ref.current) {
			ref.current.click();
		}
	};

	const handleFileUpload = async (event: any) => {
		if (!event.target.files || !event.target.files.length) {
			return;
		}

		if (event.target.files.length === 1) {
			const file = event.target.files[0];
			const data = new FormData();
			data.append('photo', file);
			onUpload(data);
			return;
		}

		onUploadMultiple(
			new Array(event.target.files.length)
				.fill(undefined)
				.map((_, i) => event.target.files[i])
				.map((file: any) => {
					const data = new FormData();
					data.append('photo', file);
					return data;
				}),
		);
	};

	const handleDragEnter = (photo: PhotoDisplay) => {
		if (photo.isPlaceholder) {
			return;
		}
		if (dragPhoto && dragPhoto.uuid === photo.uuid) {
			return;
		}
		setDropZonePhotoId(photo.uuid);
	};

	const handleDragStart = (photo: PhotoDisplay) => {
		if (photo.isPlaceholder) {
			return;
		}
		setDragPhoto(photo);
	};

	const handleDrop = (event: any) => {
		event.preventDefault();
		event.stopPropagation();

		if (!dropZonePhotoId || !dragPhoto) {
			return;
		}
		const photoToMove = { ...dragPhoto };
		const targetIndex = pictures.findIndex(picture => picture.uuid === dropZonePhotoId);
		const reordered = pictures.filter(picture => picture.uuid !== photoToMove.uuid);
		reordered.splice(targetIndex, 0, photoToMove);
		setDragPhoto(undefined);
		setDropZonePhotoId(undefined);
		onReorder(reordered.map((photo, i) => ({ ...photo, ordinal_position: i + 1 })));
	};

	const handleTagSelect = (photo: Photo, tag: string) => {
		onPhotoUpdate({
			...photo,
			caption: photo.caption || 'none',
			tag,
		});
	};

	return (
		<>
			<Title standardMargin>Let’s show off the rest of the place</Title>
			<SubHeader>
				Add at least 5 additional images for your tenants to see what to expect.
			</SubHeader>
			<Container>
				<Row>
					<ImagesHeader>Drag to reorder</ImagesHeader>
					<Button noMargin maxWidth={200} onClick={handleUploadClick}>
						Add image
					</Button>
				</Row>
				<PicturesContainer>
					{displayPhotos.map((photo, i) =>
						i === 0 ? (
							<CoverImageContainer
								key={photo.uuid}
								isPlaceholder={photo.isPlaceholder}
								isDropZone={dropZonePhotoId === photo.uuid}
								onDragEnter={() => handleDragEnter(photo)}
								onDragLeave={() => setDropZonePhotoId(undefined)}
								onDragOver={event => {
									event.stopPropagation();
									event.preventDefault();
								}}
								onDrop={handleDrop}
							>
								{photo.isPlaceholder ? (
									<PlaceHolderText>Preview image</PlaceHolderText>
								) : (
									<>
										{dropZonePhotoId === photo.uuid ? (
											<DropZoneText>Move here</DropZoneText>
										) : (
											<>
												<DeleteImageButton onClick={() => onDelete(photo.uuid)} />
												<CoverImageChip>Preview image</CoverImageChip>
												<DropdownContainer>
													<Select
														placeholder={'Tag'}
														options={tagOptions}
														selectedOption={tagOptions.find(o => o.value === photo.tag)}
														onChange={option => handleTagSelect(photo, option.value)}
														isOutline
														isThin
													/>
												</DropdownContainer>
												<UploadedImage
													src={photo.url}
													onDragStart={() => handleDragStart(photo)}
													onDragEnd={() => setDragPhoto(undefined)}
												/>
											</>
										)}
									</>
								)}
							</CoverImageContainer>
						) : (
							<ImageContainer
								key={photo.uuid}
								isPlaceholder={photo.isPlaceholder}
								isDropZone={dropZonePhotoId === photo.uuid}
								onDragEnter={() => handleDragEnter(photo)}
								onDragLeave={() => setDropZonePhotoId(undefined)}
								onDragOver={event => {
									event.stopPropagation();
									event.preventDefault();
								}}
								onDrop={handleDrop}
							>
								{photo.isPlaceholder ? (
									<PlaceHolderImage style={{ maxWidth: 56 }} />
								) : (
									<>
										{dropZonePhotoId === photo.uuid ? (
											<DropZoneText>Move here</DropZoneText>
										) : (
											<>
												<DeleteImageButton onClick={() => onDelete(photo.uuid)} />
												<DropdownContainer>
													<Select
														placeholder={'Tag'}
														options={tagOptions}
														selectedOption={tagOptions.find(o => o.value === photo.tag)}
														onChange={option => handleTagSelect(photo, option.value)}
														isOutline
														isThin
													/>
												</DropdownContainer>
												<UploadedImage
													src={photo.url}
													onDragStart={() => handleDragStart(photo)}
													onDragEnd={() => setDragPhoto(undefined)}
												/>
											</>
										)}
									</>
								)}
							</ImageContainer>
						),
					)}
				</PicturesContainer>
				<input
					ref={ref}
					type="file"
					onChange={handleFileUpload}
					style={{ display: 'none' }}
					multiple
				/>
			</Container>
		</>
	);
};

export default PictureUpload;

const Container = styled.div`
	padding-bottom: 32px;
`;

const SubHeader = styled(SubTitle)`
	color: ${colors.grey60};
	margin-bottom: 24px;
`;

const ImagesHeader = styled(SubTitle)`
	margin-top: 16px;
	margin-bottom: 0;
	font-weight: 600;
	color: ${colors.darkBlue};
`;

const ImageContainer = styled.div<{ isPlaceholder?: boolean; isDropZone?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
	height: 144px;
	width: calc(50% - 20px);
	margin: 8px 10px;
	position: relative;
	border: ${({ isPlaceholder }) => (isPlaceholder ? `2px dashed ${colors.grey20}` : `none`)};
	${({ isDropZone }) => (isDropZone ? `border: 2px dashed ${colors.darkTurquoise}` : '')};
`;

const CoverImageContainer = styled(ImageContainer)`
	width: 100%;
	height: 200px;
	margin: 0 10px;
	margin-bottom: 16px;
`;

const PlaceHolderText = styled.div`
	color: ${colors.grey60};
	font-weight: 600;
`;

const PicturesContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: auto;
	width: 100%;
`;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 16px;
`;

const UploadedImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	cursor: grab;
	border-radius: 16px;
`;

const DropZoneText = styled.div`
	color: ${colors.grey60};
	font-weight: 600;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: ${colors.white};
	opacity: 0.7;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const DropdownContainer = styled.div`
	position: absolute;
	bottom: 0;
	left: 16px;
	width: 200px;
	max-width: 50%;
	cursor: pointer;
`;

const CoverImageChip = styled.div`
	position: absolute;
	top: 16px;
	left: 16px;
	padding: 0 16px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	background: ${colors.darkTurquoise};
	color: ${colors.white};
	border-radius: 24px;
	font-size: 14px;
`;
