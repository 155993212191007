import React from 'react';

import styled from 'styled-components';

import { ReactComponent as QuestionSvg } from 'assets/images/icons/question.svg';
import theme from 'config/theme';
import InfoText from 'lib/InfoText';

const { colors } = theme;

const Container = styled.div`
	border: 1px solid ${colors.grey60};
	border-radius: 16px;
	padding: 16px;
	display: flex;
	align-items: center;
`;

const InfoBoxText = styled(InfoText)`
	margin: 0;
`;

const InfoIcon = styled(QuestionSvg)`
	margin-right: 16px;
	min-width: 24px;
`;

interface InfoBoxProps {
	text: string;
}

const InfoBox = ({ text }: InfoBoxProps) => {
	return (
		<Container>
			<InfoIcon />
			<InfoBoxText>{text}</InfoBoxText>
		</Container>
	);
};

export default InfoBox;
