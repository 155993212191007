import { FurnishedType } from 'models/listing';
import { SelectOption } from 'lib/Select';

export const furnishedOptions: SelectOption[] = [
	{
		value: FurnishedType.Fully,
		label: 'Furnished',
	},
	{
		value: FurnishedType.Semi,
		label: 'Semi-furnished',
	},
	{
		value: FurnishedType.Unfurnished,
		label: 'Unfurnished',
	},
];
