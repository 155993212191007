import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { Modal } from 'lib/Modal';
import { Cross } from 'lib/Cross';
import Button from 'lib/Button';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

import ContactUsContent from './ContactUsContent';

const { colors } = theme;

interface ContactUsModalProps {
	onClose(): void;
}

export const ContactUsModal = ({ onClose }: ContactUsModalProps) => {
	return (
		<Modal onBackgroundClick={onClose}>
			<ModalTitleContainer>
				<Cross onClick={onClose} />
			</ModalTitleContainer>
			<ModalContent>
				<ContactUsContent />
			</ModalContent>
			<ModalActionBar>
				<Button noMargin isOutline onClick={onClose}>
					Close
				</Button>
			</ModalActionBar>
		</Modal>
	);
};

export default ContactUsModal;

const ModalTitleContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 16px 24px;
	padding-top: 24px;
`;

const ModalContent = styled.div`
	padding: 24px 48px;
	overflow-y: auto;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		padding: 24px;
	}
`;

const ModalActionBar = styled.div`
	flex-shrink: 0;
	border-top: 1px solid ${colors.grey10};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px;
	padding-top: 16px;

	@media (max-width: 768px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: white;
	}
`;

const LeftMarginContainer = styled.div`
	margin-left: 24px;
	width: 100%;
`;
