import React from 'react';

import styled from 'styled-components';

import Title from 'lib/Title';
import theme from 'config/theme';
import { Feature, FeatureCategory } from 'models/property';
import SubTitle from 'lib/SubTitle';
import CheckboxRow from 'lib/CheckboxRow';
import Divider from 'lib/Divider';
import { Select } from 'lib/Select';

import FieldHeader from './FieldHeader';
import FieldSubHeader from './FieldSubHeader';

const { colors } = theme;

interface FeaturesProps {
	features: Feature[];
	onFeaturesChange(value: Feature[]): void;
}

const Features = ({ features, onFeaturesChange }: FeaturesProps) => {
	const handleFeatureToggle = (featureId: number) => {
		onFeaturesChange(
			features.map(f => ({ ...f, active: f.id === featureId ? !f.active : f.active })),
		);
	};
	const handleFeatureMetaUpdate = (featureId: number, meta: any) => {
		onFeaturesChange(
			features.map(f => ({
				...f,
				meta: f.id === featureId ? meta : f.meta,
			})),
		);
	};
	return (
		<>
			<Title standardMargin>What does your place include?</Title>
			<SubHeader>Select what this listing includes in the rental</SubHeader>
			<Container>
				<FieldHeader>Tenant favorites</FieldHeader>
				{features
					.filter(({ favourite }) => favourite)
					.map(item => (
						<div key={item.id}>
							<CheckboxRow
								text={item.name}
								checked={item.active}
								onClick={() => handleFeatureToggle(item.id)}
							/>
							{item.active &&
								item.meta_description
									.filter(meta => meta.valid && meta.input_type === 'select')
									.map(meta => (
										<div key={meta.display}>
											<FieldSubHeader>{meta.display}</FieldSubHeader>
											<Select
												placeholder={meta.prompt || 'Select'}
												options={meta.options.map(option => ({ label: option, value: option }))}
												selectedOption={
													item.meta.select && { label: item.meta.select, value: item.meta.select }
												}
												onChange={option =>
													handleFeatureMetaUpdate(item.id, { select: option.value })
												}
											/>
										</div>
									))}
						</div>
					))}
				<Divider />
				<FieldHeader>Security</FieldHeader>
				{features
					.filter(({ category }) => category === FeatureCategory.Security)
					.sort((a, b) => {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}
						return 0;
					})
					.map(item => (
						<CheckboxRow
							key={item.id}
							text={item.name}
							checked={item.active}
							onClick={() => handleFeatureToggle(item.id)}
						/>
					))}
				<Divider />
				<FieldHeader>Facilities</FieldHeader>
				{features
					.filter(({ category }) => category === FeatureCategory.Facilities)
					.sort((a, b) => {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}
						return 0;
					})
					.map(item => (
						<CheckboxRow
							key={item.id}
							text={item.name}
							checked={item.active}
							onClick={() => handleFeatureToggle(item.id)}
						/>
					))}
			</Container>
		</>
	);
};

export default Features;

const Container = styled.div`
	padding-bottom: 32px;
`;

const SubHeader = styled(SubTitle)`
	color: ${colors.grey60};
	margin-bottom: 24px;
`;
