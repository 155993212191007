import React from 'react';

import styled from 'styled-components';

import { Feature, FeatureCategory } from 'models/property';
import Title from 'lib/Title';
import theme from 'config/theme';
import SubTitle from 'lib/SubTitle';
import { notifyError } from 'lib/Notifications';

const { colors } = theme;

interface AtmosphereProps {
	features: Feature[];
	onFeaturesChange(value: Feature[]): void;
}

const Atmosphere = ({ features, onFeaturesChange }: AtmosphereProps) => {
	const handleFeatureToggle = (feature: Feature) => {
		if (
			features.filter(({ category, active }) => category === FeatureCategory.Tags && active)
				.length >= 3 &&
			!feature.active
		) {
			notifyError('You can only select 3 tags, deselect a tag to change your selection');
			return;
		}
		onFeaturesChange(
			features.map(f => ({ ...f, active: f.id === feature.id ? !f.active : f.active })),
		);
	};
	return (
		<>
			<Title standardMargin>What is it like living here?</Title>
			<SubHeader>Tell potential tenants what its like living at your property.</SubHeader>
			<Container>
				<p>
					Choose up to <b>3 tags</b> to describe your property
				</p>
				<ChipsContainer>
					{features
						.filter(({ category }) => category === FeatureCategory.Tags)
						.map(item => (
							<Chip key={item.id} checked={item.active} onClick={() => handleFeatureToggle(item)}>
								{item.name}
							</Chip>
						))}
				</ChipsContainer>
			</Container>
		</>
	);
};

export default Atmosphere;

const Container = styled.div`
	padding-bottom: 32px;
`;

const SubHeader = styled(SubTitle)`
	color: ${colors.grey60};
	margin-bottom: 24px;
`;

const Chip = styled.div<{ checked: boolean }>`
	background: ${colors.white};
	color: ${colors.darkBlue};
	border: 1px solid ${colors.darkBlue};
	border-radius: 48px;
	padding: 6px 8px;
	display: inline-flex;
	margin-right: 8px;
	margin-bottom: 8px;
	align-items: center;
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;

	${({ checked }) =>
		checked
			? `
    background: ${colors.darkBlue};
    color: ${colors.white};
  `
			: ''}
`;

const ChipsContainer = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 24px;
`;
