import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { Modal } from 'lib/Modal';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

import { CreateListingStep, CreateListingSubStep, StepStatus } from './types';
import { TopBar } from 'lib/TopBar';
import Divider from 'lib/Divider';

const { colors } = theme;

interface StepsModalProps {
	steps: {
		step: CreateListingStep;
		subSteps: {
			subStep: CreateListingSubStep;
			status: StepStatus;
			displayText: string;
		}[];
		status: StepStatus;
		displayText: string;
	}[];
	currentStep: CreateListingStep;
	currentSubStep?: CreateListingSubStep;
	onStepSelect(step: CreateListingStep, subStep?: CreateListingSubStep): void;
	onClose(): void;
}

const StepsModal = ({
	steps,
	currentStep,
	currentSubStep,
	onStepSelect,
	onClose,
}: StepsModalProps) => {
	return (
		<Modal onBackgroundClick={onClose}>
			<TopBar title="Steps" onClose={onClose} closeOnRight />
			<ModalContent>
				{steps.map(stepInfo => {
					return (
						<div key={stepInfo.step}>
							<LeftPanelStepContainer
								isSelected={false}
								onClick={() => onStepSelect(stepInfo.step)}
							>
								<LeftPanelStepText isSelected={currentStep === stepInfo.step}>
									{stepInfo.displayText}
								</LeftPanelStepText>
							</LeftPanelStepContainer>
							{stepInfo.subSteps && Boolean(stepInfo.subSteps.length) && (
								<SubStepsContainer>
									{stepInfo.subSteps.map(subStepInfo => (
										<LeftPanelSubStepContainer
											key={subStepInfo.subStep}
											onClick={() => {
												if (subStepInfo.status === StepStatus.Incomplete) {
													return;
												}
												onStepSelect(stepInfo.step, subStepInfo.subStep);
											}}
										>
											<LeftPanelStepText isSelected={currentSubStep === subStepInfo.subStep}>
												{subStepInfo.displayText}
											</LeftPanelStepText>
										</LeftPanelSubStepContainer>
									))}
								</SubStepsContainer>
							)}
							<Divider />
						</div>
					);
				})}
			</ModalContent>
		</Modal>
	);
};

export default StepsModal;

const ModalContent = styled.div`
	padding: 24px 48px;
	overflow-y: auto;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		padding: 24px;
	}
`;

const SubStepsContainer = styled.div`
	margin-top: 0;
	margin-left: 24px;
	margin-bottom: 0px;
`;

const LeftPanelStepContainer = styled.div<{ isSelected?: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 16px;
	background: ${({ isSelected }) => (isSelected ? colors.shadedGray : 'transparent')};
	margin: 0 -16px;
	margin-bottom: 16px;
	padding: 0 16px;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
`;

const LeftPanelStepText = styled.div<{ isSelected?: boolean; isDisabled?: boolean }>`
	font-weight: ${({ isSelected }) => (isSelected ? '600' : 'normal')};
	font-size: 16px;
	opacity: ${({ isSelected }) => (isSelected ? '1' : '0.7')};
	text-decoration: ${({ isSelected }) => (isSelected ? 'underlined' : 'none')};
	color: ${colors.darkBlue};
`;

const LeftPanelSubStepContainer = styled.div`
	margin-bottom: 16px;
	cursor: pointer;
`;
