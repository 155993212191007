import styled from 'styled-components';

import SubTitle from 'lib/SubTitle';
import theme from 'config/theme';

const { colors } = theme;

const FieldHeader = styled(SubTitle)`
	margin-top: 16px;
	font-weight: 600;
	color: ${colors.darkBlue};
`;

export default FieldHeader;
