import React from 'react';

import styled from 'styled-components';

import { BREAKPOINT_DESKTOP_PX, BREAKPOINT_DESKTOP_LARGE_PX } from 'constants/breakPoints';
import theme from 'config/theme';

import { Feature, Property } from 'models/property';
import ListingCardMobile from 'components/digs-search-v2/ListingCardMobile';
import { Listing } from 'models/listing';
import { LeaseType } from 'models/digs';
import userContainer from 'containers/user';
import Text from '../digs-shared/Text';

const { colors, headerHeight } = theme;

const StyledRightPanel = styled.div`
	display: none;
	min-width: 350px;
	overflow-y: auto;
	position: static;

	@media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
		display: inline;
		width: 350px;
	}

	@media (min-width: ${BREAKPOINT_DESKTOP_LARGE_PX}px) {
		width: 450px;
	}
`;

const RightPanelContent = styled.div`
	display: flex;
	flex-direction: column;
	margin: 32px;
	margin-bottom: 0px;
	padding-bottom: 16px;
	align-items: center;
	justify-content: flex-start;
	overflow-y: auto;
	height: calc(100% - 32px);
	gap: 10px;
	position: relative;
`;

const propertyToListing = (property: Property, features: Feature[]): Listing =>
	(({
		uuid: '',
		title: property.title,
		lease_term: property.lease_term,
		lease_term_flexible: property.lease_term_flexible,
		availability_date: property.availability_date,
		photos: property.photos,
		rooms: property.rooms,
		lease_type: property.lease_type,
		location: property.location,
		major_type: property.lease_type,
		sub_type: property.sub_type,
		price: property.price || [...property.rooms].sort((a, b) => a.price - b.price)[0].price,
		display_price_from: property.lease_type === LeaseType.RoomByRoom,
		bedroom_count: property.total_bedrooms,
		university_distances: [],
		currency: property.currency,
		billing_cycle: property.billing_cycle,
		external: false,
		features: features,
		furnished: property.furnished,
		landlord: {
			is_trusted_landlord: userContainer.state.v2.is_trusted_landlord,
		},
	} as unknown) as Listing);

interface RightPanelProps {
	property?: Property;
	features: Feature[];
}

const RightPanel = ({ property, features }: RightPanelProps) => (
	<StyledRightPanel>
		<RightPanelContent>
			<Text>Listing preview</Text>
			{property && (
				<ListingCardMobile
					{...propertyToListing(property, features)}
					onClick={() => {}}
					widthPx={280}
					fixedHeightPx={320}
					hideFavourite
				/>
			)}
		</RightPanelContent>
	</StyledRightPanel>
);

export default RightPanel;
