import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import SubTitle from 'lib/SubTitle';
import InfoText from 'lib/InfoText';

const { colors } = theme;

const Title = styled.div`
	font-weight: 600;
	font-size: 16px;
`;

const Description = styled.div`
	margin-top: 8px;
	margin-bottom: 16px;
	font-size: 14px;
`;

const ContactUsHeader = styled(Description)`
	margin: 0;
	margin-bottom: 8px;
`;

const ContactText = styled(Title)`
	color: ${colors.darkTurquoise};
`;

const ContactLink = styled.a`
	color: ${colors.darkTurquoise};
`;

const UnderlinedText = styled(ContactLink)`
	text-decoration: underline;
`;

const ContactUsContainer = styled.div`
	text-align: center;
`;

const contactNumber = ['+27', '79', '433', '1143'];
const contactEmail = 'landlords@digsconnect.com';

const ContactUsContent = () => (
	<ContactUsContainer>
		<Title>👋 We're here to help!</Title>
		<Description>Need some assistance getting your listing on DigsConnect?</Description>
		<ContactUsHeader>Contact us on:</ContactUsHeader>
		<ContactText>
			<ContactLink href={`tel:${contactNumber.join('')}`}>{contactNumber.join(' ')}</ContactLink> •{' '}
			<UnderlinedText href={`mailto:${contactEmail}`}>{contactEmail}</UnderlinedText>
		</ContactText>
	</ContactUsContainer>
);

export default ContactUsContent;
